import React from 'react';
import propTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from '../style/GlobalStyle';
import { theme } from '../style/theme';

const StyledMainLayout = styled.main`
  & .container {
    width: 315px;
    margin: 0 auto;

    @media (min-width: 1024px) {
      width: 972px;
    }

    @media (min-width: 1366px) {
      width: 1120px;
    }
  }
`;

const MainLayout = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <StyledMainLayout>{children}</StyledMainLayout>
    </ThemeProvider>
  </>
);

MainLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default MainLayout;
