export const theme = {
  colors: {
    primary: '#000',
    secondary: '#fff',
    additional: '#d7b1e6',
  },

  width: {
    mobile: '315px',
    tablet: '972px',
    laptop: '1120px',
    desktop: '1120px',
  },

  size: {
    mobile: '425px',
    tablet: '1024px',
    laptop: '1366px',
    desktop: '1920px',
  },
};

export const device = {
  mobile: `(min-width: ${theme.size.mobile})`,
  tablet: `(min-width: ${theme.size.tablet})`,
  laptop: `(min-width: ${theme.size.laptop})`,
  desktop: `(min-width: ${theme.size.desktop})`,
};
